import React from 'react';
import { Link } from '@carbon/react'; // Importing Link from @carbon/react
import './Auth_Footer.scss';
import Footerlogo from "../../../assets/images/CERYX_reverse.png";

export const AuthFooter = () => (
  <div className="footer"> {/* Moved className to outermost div */}
    <div className="footer-container">
      <div className="footer-nav">
        <ul className="footer-link">
          
          <li className="footer-title">
            <a href="/" className="footer-link">
              Contact
            </a>
          </li>
          <li className="footer-title">
            <a href="/" className="footer-link">
              Privacy
            </a>
          </li>
          <li className="footer-title">
            <a href="/" className="footer-link">
              Terms of use
            </a>
          </li>
        </ul>
        <div className="branding-container">
        <span className="branding-text" style={{ color: '#c6c6c6' }}>
            Powered by <Link href='https://www.ceryxdigital.com/'><img src={Footerlogo} alt="Footer Logo"  className="footer-logo" /></Link>
          </span>
        </div>
      </div>
    </div>
  </div>
); 