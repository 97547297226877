import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'carbon-components-react';

function Home() {
  const navigate = useNavigate(); // Moved useNavigate inside the Home component

  const handleSignUp = () => {
    navigate("/Sign-Up");
  };
  
  return (
    <>
      <div style={{display:'flex',justifyContent:'center',marginBlock:'20%'}}>

      <Button
        
        onClick={handleSignUp}
        kind="tertiary"
        className="custom-width-button"
      >
        Create an account
    
      </Button>

      </div>
    </>
  );
}

export default Home;
