import React, { useState } from "react";
import {
  Content,
  Header,
  HeaderContainer,
  HeaderMenuButton,
  HeaderName,
  HeaderNavigation,
  HeaderMenu,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
  HeaderSideNavItems,
  SkipToContent,
  SideNav,
  SideNavDivider,
  SideNavItems,
  SideNavLink,
  SideNavMenu,
  SideNavMenuItem,
  Switcher,
  SwitcherItem,
  SwitcherDivider,
} from "@carbon/react";
import { HeaderMenu as HeaderMenuNative } from "@carbon/react";
import { Button } from "@carbon/react";
import "./Auth_Header.scss";

export const Auth_Header = (args) => (
  <HeaderContainer
    render={({ isSideNavExpanded, onClickSideNavExpand }) => (
      <>
        <div className="nav-container">
          <Header aria-label="IBM Platform Name">
            <HeaderName href="#" prefix="">
              <div
                style={{
                  maxWidth: "99rem",
                  marginRight: "auto",
                  marginLeft: "auto",
                  
                }}
                className="headertitle"
              >
                <span style={{ fontWeight: "normal", fontSize: "20px" }}>
                Propl
                </span>
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "20px",
                    fontWeight: "200px",
                  }}
                >
                  LMS
                </span>
              </div>
            </HeaderName>
          </Header>
        </div>
      </>
    )}
  />
);
