import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './Components/Auth/Signup/Signup';
import Home from './Website/Home';
import Loginpage from './Components/Auth/Login/Login';
import Password from './Components/Auth/Login/Password';
function App() {
  return (
    <Router>
      <Routes>
        

        <Route path="*" element={<Home />} />
        <Route path="/Sign-Up" element={<SignUp />} />
     

      </Routes>
    </Router>
  );
}

function NotFound() {
  return <h1>Page not found</h1>;
}

export default App;
