import React, { useState } from "react";
import { Grid, Column, TextInput, Button, Heading, Link, FormGroup, Stack, Checkbox } from "@carbon/react";
import "./signup.scss";
import { AuthFooter } from "../Layout/Auth_Footer";
import { Auth_Header } from "../Layout/Auth_Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import loginSideImg from "../../../assets/images/background-000.jpg";
import { useNavigate } from "react-router-dom";

// Validation functions
const validateDomain = (domain) => {
  const domainRegex = /^[a-z0-9]{3,}$/; // Only lowercase letters and numbers, at least 3 characters long
  if (!domainRegex.test(domain)) {
    return "Please enter a valid domain. Use only lowercase letters and numbers; at least 3 characters long; no spaces or special characters.";
  }
  return "";
};

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email validation regex
  if (!emailRegex.test(email)) {
    return "Please enter a valid email address.";
  }
  return "";
};

const validateUsername = (username) => {
  const usernameRegex = /^(?=.*[0-9])[a-zA-Z0-9]{3,}$/; // At least 3 characters with at least one number
  if (!usernameRegex.test(username)) {
    return "Username must be at least 3 characters long and contain at least one number.";
  }
  return "";
};

const validatePassword = (password) => {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,}$/; // At least 8 characters with at least one digit, one lowercase letter, one uppercase letter, and one special character
  if (!passwordRegex.test(password)) {
    return "Password must be at least 8 characters long and contain at least one digit, one lowercase letter, one uppercase letter, and one special character.";
  }
  return "";
};

const usedEmails = new Set();

const checkUsernameAvailability = async (username) => {
  try {
    const response = await axios.post('https://api.proplup.io/propluprest/authentication/api/check/username', { val: username });
    if (response.data.type === "ERROR" && response.data.message.details === "This username can be used.") {
      return { isValid: true }; // The username can be used
    } else if (response.data.type === "ERROR") {
      return { isValid: false, message: response.data.message.details }; // Other error messages
    }
    return { isValid: true }; // Assume valid if no error type is returned
  } catch (error) {
    console.error("Error validating username:", error.response?.data || error.message);
    if (error.response && error.response.status === 400 && error.response.data.type === "ERROR") {
      if (error.response.data.message.details === "This username can be used.") {
        return { isValid: true }; // Treat this specific error message as valid
      } else {
        return { isValid: false, message: error.response.data.message.details }; // Other error messages
      }
    }
    throw new Error("An error occurred while validating the username. Please try again later.");
  }
};

const checkEmailAvailability = async (email) => {
  try {
    const response = await axios.post('https://api.proplup.io/propluprest/authentication/api/validate/email', { val: email });
    if (response.data.type === "SUCCESS" && response.data.message.details === "This email is already linked to an account; please log in or use a different one.") {
      return { isValid: false, message: response.data.message.details }; // Email already linked to an account
    } else if (response.data.type === "ERROR") {
      console.log("This email can be used."); // Print message when email can be used
      return { isValid: true };
    }
  } catch (error) {
    console.error("Error validating email:", error.response?.data || error.message);
    throw new Error("An error occurred while validating the email. Please try again later.");
  }
};

const createUser = async (formData) => {
  try {
    const response = await axios.post(`https://api.proplup.io/propluprest/authentication/api/create/user`, {
      username: formData.username,
      password: formData.password,
      email: formData.email,
      name: formData.firstName,
      lastName: formData.lastName,
      refrenceId: formData.domain,
      gender: "MALE",
      birthDay: "",
      isUseEmailID: "",
    });
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw new Error("An error occurred while creating the user. Please try again later.");
  }
};

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    domain: "",
  });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleBlur = (event) => {
    const { id, value } = event.target;
    let errors = { ...formErrors };
    switch (id) {
      case "domain":
        errors.domain = validateDomain(value);
        break;
      case "email":
        errors.email = validateEmail(value);
        break;
      case "username":
        errors.username = validateUsername(value);
        break;
      case "password":
        errors.password = validatePassword(value);
        break;
      default:
        break;
    }
    setFormErrors(errors);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate the form
    const errors = {
      domain: validateDomain(formData.domain),
      email: validateEmail(formData.email),
      username: validateUsername(formData.username),
      password: validatePassword(formData.password),
    };
    setFormErrors(errors);

    // Check if there are any errors
    if (Object.values(errors).some(error => error !== "")) {
      return;
    }

    // Check for duplicate username
    try {
      const usernameValidationResponse = await checkUsernameAvailability(formData.username);
      if (!usernameValidationResponse.isValid) {
        toast.error(usernameValidationResponse.message);
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    // Check for duplicate email
    try {
      const emailValidationResponse = await checkEmailAvailability(formData.email);
      if (emailValidationResponse.type === "ERROR") {
        toast.error(emailValidationResponse.message.details);
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    // Proceed with submission
    try {
      const createUserResponse = await createUser(formData);
      toast.success("User created successfully!", {
        onClose: () => navigate("/")
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleLogin = () => {
    window.location.href = 'https://auth.proplup.io/';
  };

  return (
    <>
      <div className="signup-container">
        <Auth_Header />
        <Grid>
          <Column sm={4} lg={11}>
            <img className="login__image" src={loginSideImg} alt="Your Image" />
          </Column>

          <Column sm={4} lg={5}>
            <div className="form-container">
              <form onSubmit={handleSubmit}>
                <FormGroup className="form-group" legendText="">
                  <Heading>
                    <div className="label">
                      <span className="temp">Let’s start, </span>
                      <span className="span">it’s quick!</span>
                      <span className="text-wrapper">&nbsp;</span>
                    </div>
                  </Heading>
                  <Stack gap={6}>
                    <div className="border"></div>
                    <TextInput
                      id="domain"
                      labelText="Domain Name (propllms.com)"
                      placeholder="propllms.com"
                      value={formData.domain}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!formErrors.domain}
                      invalidText={formErrors.domain}
                    />
                    <TextInput
                      id="email"
                      labelText="Email"
                      placeholder="email@yourcompanyname.com"
                      value={formData.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!formErrors.email}
                      invalidText={formErrors.email}
                    />
                    <TextInput
                      id="firstName"
                      labelText="First Name"
                      placeholder="Enter first name"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    <TextInput
                      id="lastName"
                      labelText="Last Name"
                      placeholder="Enter last name"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    <TextInput
                      id="username"
                      labelText="Username"
                      placeholder="Enter username"
                      value={formData.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!formErrors.username}
                      invalidText={formErrors.username}
                    />
                    <TextInput.PasswordInput
                      id="password"
                      labelText="Password"
                      placeholder="Password"
                      autoComplete="true"
                      value={formData.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!formErrors.password}
                      invalidText={formErrors.password}
                    />
                    <div className="checkbox-container">
                      <Checkbox
                        labelText={
                          <>
                            I accept the{" "}
                            <a href="link-to-terms-of-service">
                              Terms of Service
                            </a>
                          </>
                        }
                        id="checkbox-label-1"
                        checked={termsAccepted}
                        onChange={() => setTermsAccepted(!termsAccepted)}
                      />
                    </div>
                    <Button
                      className="custom-width-button"
                      type="submit"
                      disabled={!termsAccepted}
                      style={{ display: 'block', width: '100%', maxWidth: '100%' }}
                    >
                      Create free account
                    </Button>
                  </Stack>
                  <p className="login-link">
                    Already have an account?{" "}
                    <Link onClick={handleLogin} href="#" className="login-text">
                      Login
                    </Link>
                  </p>
                </FormGroup>
              </form>
            </div>
          </Column>
        </Grid>
      </div>
      <AuthFooter />
      <ToastContainer />
    </>
  );
};

export default SignUp;
